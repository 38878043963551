<!-- =========================================================================================
    File Name: CarouselDefault.vue
    Description: Default Carousel demo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <swiper 
    class="announcement-carousel w-full"
    ref="announcementSwiper" 
    :options="swiperOptions"
  >
    <swiper-slide v-for="(item,index) in announcements" :key="index" class="carousel-swiper">
      <vx-card :title="item.title" class="carousel-card">
        <div v-html="item.content"></div>

        <div v-if="item.announcmentUrl" class="mt-4">
          <a :href="item.announcmentUrl" class="announcement-link" target="_blank">Details</a>
        </div>
      </vx-card>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
// See: vue-awesome-swiper docs https://github.com/surmon-china/vue-awesome-swiper
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide, pagination, navigation, autoPlay } from 'vue-awesome-swiper'

export default{
  components: {
    swiper,
    swiperSlide,
    pagination,
    navigation,
    autoPlay
  },
  props: {
    announcements: { type: Array, default: [] },
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 5000,
        }
      }
    }
  },
  computed: {
    announcementSwiper() {
      return this.$refs.announcementSwiper.$swiper
    }
  },
}
</script>

<style>
  .announcement-carousel .carousel-card{
    height: 350px;
  }
  .announcement-carousel .announcement-link {
    font-weight: 500;
    font-size: 16px;
  }
</style>
