<template>
  <div class="w-full">
    <!-- <vx-card title="Frill" style="width:100%; height:100%">
      <div slot="no-body" class="mx-4">
        <iframe id="frillWidget" 
          :src="iframe.src"
          width="100%"
          height="100%" 
          class="frill-iframe"
          ref="frillWidgetFrame">
        </iframe>
      </div>
    </vx-card> -->
     <iframe id="frillWidget" 
        :src="iframe.src"
        width="100%"
        height="100%" 
        scrolling="yes" 
        class="frill-iframe"
        ref="frillWidgetFrame">
    </iframe>
  </div>
</template>

<script>
// See: vue-awesome-swiper docs https://github.com/surmon-china/vue-awesome-swiper

export default{
  components: {
  },
  props: {
    
  },
  data() {
    return {
      iframe: {
        // HTML served from wwwroot in server
        src: `https://app.loopedin.io/travelgenix`,
        loaded: false
      },
    }
  },
  mounted () {

  },
  methods: {
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
      }
      return result;
    }
  }
}
</script>

<style>
.frill-iframe{
  border: none;
  border-radius: 5px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px 10px 0px 10px;
}
</style>
