<template>
  <travio-modal v-show="showAnnouncementsDialog" width='650' height="350" zIndex="99999">
    <h3 slot="header">Announcements</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div v-for="(item, index) in announcements" :key="index">
            <h4 class="mb-3" v-html="item.title"></h4>
            <div v-html="item.content"></div>

            <div v-if="item.announcmentUrl" class="mt-4">
              <a :href="item.announcmentUrl" class="announcement-link" target="_blank">Details</a>
            </div>
            <vs-divider class="mb-5" />
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button color="primary" @click="close" type="filled">Dismiss</vs-button>
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'

export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      showAnnouncementsDialog: false,
    }
  },
  props: {
    announcements: { required: true},
  },
  computed: {
   
  },
  async mounted (){
    this.showAnnouncementsDialog=true
  },
  methods: {
    close () {
      this.$emit('onClose')
    }
  }
  
}
</script>

<style>
</style>
