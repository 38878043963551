<template>
  <vx-card title="Breaking Travel News - Your Source For Travel Industry News" class="travel-newsfeed">
    <div slot="no-body" class="tabs-container px-6 pt-6">
      <div class="vx-row">
        <div class="vx-col lg:w-2/3 sm:w-full">
          <div v-for="(feed, index) in newsFeed" :key=index class="mb-4">
            <h5><a class="heading-link" :href="feed.link" target="_blank">{{feed.title}}</a></h5>
            <p class="mt-1 text-sm">
              <span>Posted: {{(new Date(feed.publishedDate)).toLocaleDateString("en-gb")}}</span>
            </p>
            <p class="mt-1" v-html="feed.description"></p>
          </div>
        </div>
      </div>

      <div class="mt-4"></div>
    </div>
  </vx-card>
</template>

<script>
export default{
  components: {
    
  },
  props: {
    newsFeed: { type: Array, default: [] },
  },
  data() {
    return {

    }
  },
  mounted () {
  }
}
</script>

<style>
  .travel-newsfeed .heading-link {
    color: rgba(var(--vs-primary));
    cursor: pointer
  }
  .feedflare {
    display: flex
  }
</style>
