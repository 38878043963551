<template>
  <div>
	  <h4>Welcome to your travelify.io administration portal, {{activeUserInfo.displayName}}</h4>
    <div class="vx-row match-height mt-6">
        <div class="vx-col w-1/3 mb-base">
          <announcement-carousel :announcements="announcements"/>
        </div>
        <div class="vx-col w-2/3 mb-base">
          <frill-embedded-widget />
        </div>
        <!-- <div class="vx-col lg:w-1/3 mb-base">
          <travel-news-feed />
        </div> -->
    </div>
    <div class="vx-row match-height mt-6">
      <div class="vx-col w-full mb-base">
        <travel-news-feed :newsFeed="newsFeed"/>
      </div>
    </div>
    <announcements-dialog
      v-if="showAnnouncementsDialog"
      :announcements="announcementPopups"
      @onClose="showAnnouncementsDialog=false"
    />

  </div>
</template>

<script>
import AnnouncementCarousel from './AnnouncementCarousel.vue'
import FrillEmbeddedWidget from './FrillEmbeddedWidget.vue'
import TravelNewsFeed from './TravelNewsFeed.vue'
import AnnouncementsDialog from './components/AnnouncementsDialog.vue'

export default {
  components: {
    AnnouncementCarousel,
    FrillEmbeddedWidget,
    TravelNewsFeed,
    AnnouncementsDialog,
  },
  data() {
    return {
      prop1: 'test',
      announcements: [],
      announcementPopups: [],
      newsFeed: [],
      showAnnouncementsDialog: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async mounted () {
    this.$vs.loading()
    const feedResponse = await this.$http.get(`api/announcements/travelNewsFeed`)
    this.newsFeed = feedResponse.data

    const announcementResponse = await this.$http.get(`api/announcements`)
    this.announcements = announcementResponse.data

    this.announcementPopups = this.announcements && this.announcements.filter(x => x.popup) || []
    if(this.announcementPopups.length > 0) {
      this.showAnnouncementsDialog = true
    }
    
    this.$vs.loading.close() 
  },
  methods: {
    
  }
};
</script>