var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "full", pageTitle: _vm.pageTitle } },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-2" }, [
              _c("span", [
                _c("strong", [_vm._v(" " + _vm._s(_vm.searchIdAndKey))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _vm.searchCriteria.searchUserId
              ? _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row" }, [
                    _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                      _c("span", [_c("strong", [_vm._v(" User:")])]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-3/4 w-full" },
                      [
                        _c("router-link", { attrs: { to: _vm.userLink } }, [
                          _vm._v(
                            _vm._s(_vm.searchCriteria.searchUserId) +
                              " / " +
                              _vm._s(_vm.searchCriteria.searchUserScope)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Search Type:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.searchType)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Trip Type:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.tripType)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Currency:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.currency)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Language:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.language)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Environment:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.environment)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("span", [_c("strong", [_vm._v(" Nationality:")])]),
                ]),
                _c("div", { staticClass: "vx-col md:w-3/4 w-full" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.searchCriteria.nationality)),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm.searchCriteria.flightSearchCriteria
            ? _c("FlightSearchCriteria", {
                attrs: {
                  flightSearchData: _vm.searchCriteria.flightSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.searchCriteria.accommodationSearchCriteria
            ? _c("AccommodationSearchCriteria", {
                attrs: {
                  accomSearchData:
                    _vm.searchCriteria.accommodationSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.searchCriteria.ticketSearchCriteria
            ? _c("TicketsAttractionsCriteria", {
                attrs: {
                  ticketSearchData: _vm.searchCriteria.ticketSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.searchCriteria.carRentalSearchCriteria
            ? _c("CarRentalCriteria", {
                attrs: {
                  carRentalData: _vm.searchCriteria.carRentalSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.searchCriteria.transferSearchCriteria
            ? _c("TransferSearchCriteria", {
                attrs: {
                  transferData: _vm.searchCriteria.transferSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.searchCriteria.airportExtrasSearchCriteria
            ? _c("AirportExtraSearchCriteria", {
                attrs: {
                  airportData: _vm.searchCriteria.airportExtrasSearchCriteria,
                  distUnit: _vm.distanceUnit,
                },
              })
            : _vm._e(),
          _vm.stageDropDown.length > 0
            ? _c("div", { staticClass: "vx-row mt-6" }, [
                _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col w-full" }, [_c("span")]),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("v-select", {
                          attrs: {
                            reduce: (x) => x.code,
                            clearable: false,
                            options: _vm.stageDropDown,
                          },
                          model: {
                            value: _vm.bookStageId,
                            callback: function ($$v) {
                              _vm.bookStageId = $$v
                            },
                            expression: "bookStageId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2" },
                  [
                    _c("vs-button", { on: { click: _vm.VisitSite } }, [
                      _vm._v("Visit Site"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleCancel } },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }